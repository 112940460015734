// @flow strict
import React from "react";
import styles from "./Copyright.module.scss";

type Props = {
  copyright: string
};

const Copyright = ({ copyright }: Props) => (
  <div className={styles["copyright"]}>
    {copyright.split(".")[0]}
    <br></br>
    {copyright.split(".")[1]}
  </div>
);

export default Copyright;
